<template>
  <div class="project-wrapper" v-loading="loading">
      <el-form ref="form" :model="form" label-position="right" label-width="100px" :rules="rules">
        <el-form-item label="规则配置">
          <div>
            <el-radio-group v-model="form.ruleType" @change="changeType">
              <el-radio-button :label="0" :value="0">普通</el-radio-button>
              <el-radio-button :label="1" :value="1">高级</el-radio-button>
            </el-radio-group>
          </div>
        </el-form-item>
        <el-form-item label="主题名称" prop="typeName" class="words">
          <el-input style="width: 100%" v-model="form.typeName" />
        </el-form-item>
        <el-form-item label="核心词" class="words" prop="coreWords" v-if="form.ruleType === 0">
          <textarea v-model="form.coreWords"></textarea>
          <p class="rules">核心词用于分类依据，文章包含以上任一核心词，则系统判别为属于该主题。词与词之间用、号连接。例如：微软、苹果。</p>
          <p class="rules">英文字母不区分大小写</p>
        </el-form-item>
        <el-form-item label="同现词" class="words" v-if="form.ruleType === 0">
          <textarea v-model="form.homonymWords"></textarea>
          <p class="rules">同现词，必须与核心词同时出现的词，词与词之间用、号连接。</p>
          <p class="rules">英文字母不区分大小写</p>
        </el-form-item>
        <el-form-item label="排除词" class="words" v-if="form.ruleType === 0">
          <textarea v-model="form.exclusionWords"></textarea>
          <p class="rules">排除词用于分类时限制无关信息被判别到该主题下。当排除词在核心词附近的前后出现时，系统将认为这条信息虽然含有核心词但仍然是无关信息而从避免将其分类至该主题下，词与词之间用、号连接。</p>
          <p class="rules">英文字母不区分大小写</p>
        </el-form-item>

        <el-form-item label="命中规则" class="words" prop="matchRule" v-if="form.ruleType === 1">
          <el-input
            type="textarea"
            :rows="5"
            placeholder="请输入命中规则"
            v-model="form.matchRule">
          </el-input>
          <p class="rules">通过英文括号、竖线、加号进行组合 (&nbsp;&nbsp;)&nbsp;&nbsp;|&nbsp;&nbsp;+</p>
          <p class="rules">示例1：(苹果+(iPhone|手机))|(Apple+(iPhone|手机))|(苹果+(iPad|平板))|(Apple+(iPad|平板))|(苹果+(MacBook|笔记本))|(Apple+(MacBook|笔记本))</p>
          <p class="rules">示例2：((苹果|Apple)+(iPhone|手机))|((苹果|Apple)+(iPad|平板))|((苹果|Apple)+(MacBook|笔记本))</p>
          <el-button style="float: right; margin-top: 20px" type="primary" @click="testMyRule(form.matchRule)">测试命中规则</el-button>
        </el-form-item>

        <el-form-item label="排除规则" class="words" v-if="form.ruleType === 1">
          <el-input
            type="textarea"
            :rows="5"
            placeholder="请输入排除规则"
            v-model="form.exclusionRule">
          </el-input>
          <p class="rules">通过英文括号、竖线、加号进行组合 (&nbsp;&nbsp;)&nbsp;&nbsp;|&nbsp;&nbsp;+</p>
          <el-button style="float: right; margin-top: 20px" type="primary" @click="testMyRule(form.exclusionRule)">测试排除规则</el-button>
        </el-form-item>

        <el-form-item label="媒体来源" style="width: 100%">
          <el-checkbox-group v-model="industryArray">
            <el-checkbox v-for="item in industryList" :label="item.id" :name="item.name" :key="item.id">{{ item.name }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>

        <el-form-item label="排除站点" style="width: 100%">
          <el-button type="primary" @click="selectExuldedSites">管理排除站点</el-button>
        </el-form-item>

        <el-form-item style="width: 100%; text-align: right">
          <el-button  size="medium" @click="goBack">返回</el-button>
          <el-button  type="primary" size="medium" @click="submit">保存</el-button>
        </el-form-item>
      </el-form>
    <el-dialog
      title="规则测试"
      :visible.sync="dialogVisible"
      width="30%">
      <span>{{ ruleText }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="排除站点"
      :visible.sync="unmatchSiteDialog"
      width="40%">
      <div style="text-align: left; margin-bottom: 10px; margin-top: -20px">
        <el-button size="small" type="primary" @click="drawer = true, listAllSite()">添加</el-button>
      </div>
      <el-table
        v-loading="exLoading"
        :data="exuldedArray"
        stripe
        style="width: 100%">
        <el-table-column
          prop="siteDomain"
          label="网站域名">
        </el-table-column>
        <el-table-column
          prop="siteName"
          label="网站名称">
        </el-table-column>
        <el-table-column width="70" label="操作" align="center">
          <template scope="{ row,$index }">
            <el-button @click="delExcludeSite(row)" type="danger" icon="el-icon-delete" size="mini"></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <el-drawer
      title="所有站点"
      size="40%"
      :visible.sync="drawer"
      direction="ltr">
      <div style="padding: 20px; padding-top: 0px">
        <el-input v-model="keyword" @change="changeKeyword" placeholder="输入关键词检索" ></el-input>
        <el-table
          ref="multipleTable"
          :data="tableData"
          v-loading="tableLoading"
          @selection-change="handleSelectionChange"
          stripe
          style="width: 100%">
          <el-table-column
            type="selection"
            width="55">
          </el-table-column>
          <el-table-column
            prop="siteDomain"
            label="网站域名">
          </el-table-column>
          <el-table-column
            prop="siteName"
            label="网站名称">
          </el-table-column>
        </el-table>
        <div style="text-align: right; padding-top: 20px">
          <el-button size="small" type="primary" @click="addExculedSite">确定</el-button>
        </div>
        <el-pagination
          style="text-align: right; margin-top: 20px"
          background
          :current-page="currentPage"
          @current-change="handleCurrentChange"
          layout="prev, pager, next"
          :total="tableCount">
        </el-pagination>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import eventHub from '@/utils/eventHub'
import { addMonitorSubject, selMenu, updateMonitorSubject, testRule, selectExuldedSites, listAllSite, addExcludeSite, delExcludeSite } from '@/api/settings/MonitorSubject'
import { getNav } from '@/api/layout'
import {listToTree} from '@/utils/index.js'
export default {
  data () {
    return ({
      unmatchSiteDialog: false,
      exLoading: false,
      tableLoading: false,
      drawer: false,
      form: {
        id: '',
        typeName: '',
        coreWords: '',
        homonymWords: '',
        exclusionWords: '',
        cid: '',
        parentId: '',
        ruleType: 0,
        matchRule: '',
        exclusionRule: ''
      },
      industryArray: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
      industryList: [
        {
          id: 1,
          name: '网媒'
        },
        {
          id: 2,
          name: '贴吧'
        },
        {
          id: 3,
          name: '微信'
        },
        {
          id: 4,
          name: '博客'
        },
        {
          id: 5,
          name: '论坛'
        },
        {
          id: 6,
          name: '电子报'
        },
        {
          id: 7,
          name: '微博'
        },
        {
          id: 8,
          name: '其它'
        },
        {
          id: 9,
          name: '问答'
        },
        {
          id: 10,
          name: '客户端'
        },
        {
          id: 11,
          name: '视频'
        }
      ],
      loading: true,
      id: '',
      cid: '',
      type: '',
      rules: {
        typeName: [{ required: true, message: '请输入主题名称', trigger: 'blur' }],
        coreWords: [{ required: true, message: '请输入核心词', trigger: 'blur' }],
        matchRule: [{ required: true, message: '请输入命中规则', trigger: 'blur' }]
      },
      dialogVisible: false,
      ruleText: '',
      keyword: '',
      currentPage: 1,
      tableData: [],
      tableCount: 0,
      exuldedArray: [],
      multipleSelection: []
    })
  },
  methods: {
    toggleSelection (rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },
    handleSelectionChange (val) {
      this.multipleSelection = val
      console.log(this.multipleSelection)
    },
    changeType () {
      this.$forceUpdate()
    },
    async viewupdateMonitorSubject (id) {
      try {
        this.form.id = id
        this.form.parentId = 0
        if (this.$route.params.cid === 'undefined') {
          this.form.cid = null
        } else {
          this.form.cid = this.$route.params.cid
        }

        if (this.form.homonymWords === '') {
          this.form.homonymWords = null
        }
        if (this.form.exclusionWords === '') {
          this.form.exclusionWords = null
        }
        this.form.sIdArray = this.industryArray
        const res = await updateMonitorSubject(this.form)
        if (this.$route.params.cid === 'undefined') {
          this.$router.push('/settings/theme/')
        } else {
          this.$router.push('/settings/theme/show/' + this.cid)
        }
        console.log(res)
        return res
      } catch (err) {
        console.log(err)
      }
    },
    async refreshMenu () {
      const res = await getNav()
      const [tree,map] = listToTree(res.data)
      this.$store.commit('app/SET_NAV_LIST',tree)
    },
    async viewselMenu (id) {
      this.loading = true
      try {
        const res = await selMenu({ id: id })
        this.form = res.data
        this.industryArray = res.data.sIdArray
        if (this.form.ruleType === 1) {
          this.form.matchRule = this.form.coreWords
          this.form.exclusionRule = this.form.exclusionWords
        }
        this.loading = false
      } catch (err) {
        console.log(err)
      }
    },
    async viewSaveNewProject () {
      try {
        if (this.$route.params.cid === 'undefined') {
          this.form.cid = null
        } else {
          this.form.cid = this.$route.params.cid
        }
        if (this.form.homonymWords === '') {
          this.form.homonymWords = null
        }
        if (this.form.exclusionWords === '') {
          this.form.exclusionWords = null
        }
        console.log(this.form)
        this.form.excludeSiteList = this.exuldedArray
        this.form.sIdArray = this.industryArray
        const res = addMonitorSubject(this.form)
        this.data = res.data
        var param = this.$route.params
        var r = this.$router
        setTimeout(function () {
          if (param.cid === 'undefined') {
            r.push('/settings/theme/')
          } else {
            r.push('/settings/theme/show/' + this.cid)
          }
        }, 2000)
        return res
      } catch (err) {
        console.log(err)
      }
    },
    async testMyRule (rule) {
      if (!rule) {
        this.$message.warning('规则为空无法进行测试')
        return
      }
      const res = await testRule({ testRule: rule })
      if (res.code === 0) {
        this.ruleText = res.data.join(' | ')
        this.dialogVisible = true
        this.$message.success('恭喜，您配置的规则语法上没有错误')
      } else {
        this.$message.error(res.msg)
      }
    },
    // 这里书写插入的方法
    submit () {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          this.loading = true
          if (this.type === 'edit') {
            this.id = this.$route.params.id
            const res = await this.viewupdateMonitorSubject(this.id)
            if (res.code === 0) {
              this.$message({
                message: '修改成功',
                type: 'success'
              })
              // this.refreshMenu()
            } else {
              this.$message.error(res.msg)
            }
            this.loading = false
          } else {
            const res = await this.viewSaveNewProject()
            if (res.code === 0) {
              this.$message({
                message: '创建成功',
                type: 'success'
              })
            } else {
              this.$message.error(res.msg)
            }
            this.loading = false
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    goBack () {
      if (this.$route.params.cid === 'undefined') {
        this.$router.push('/settings/theme/')
      } else {
        this.$router.push('/settings/theme/show/' + this.$route.params.cid)
      }
    },
    async selectExuldedSites () {
      this.unmatchSiteDialog = true
      if (this.type === 'edit') {
        this.exLoading = true
        const res = await selectExuldedSites({ sortId: this.$route.params.id })
        this.exuldedArray = res.data
        this.exLoading = false
      }
    },
    async changeKeyword () {
      this.currentPage = 1
      this.listAllSite()
    },
    async listAllSite () {
      this.tableLoading = true
      const res = await listAllSite({ keyword: this.keyword, currentPage: this.currentPage })
      this.tableData = res.data
      this.tableCount = res.count
      this.tableLoading = false
    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.listAllSite()
    },
    async addExculedSite () {
      if (this.multipleSelection.length === 0) {
        this.$message.warning('您至少应选择一个需要添加的排除站点')
        return
      }
      if (this.type === 'edit') {
        this.tableLoading = true
        const res = await addExcludeSite({ msId: this.$route.params.id, list: this.multipleSelection })
        if (res.code === 0) {
          this.$message.success(res.msg)
          this.$refs.multipleTable.clearSelection()
          this.selectExuldedSites()
        } else {
          this.$message.error(res.msg)
        }
        this.tableLoading = false
      } else {
        for (var i = 0; i < this.multipleSelection.length; i++) {
          if (this.exuldedArray.indexOf(this.multipleSelection[i]) === -1) {
            this.exuldedArray.push(this.multipleSelection[i])
          }
        }
        this.$message.success('排除站点已添加完成')
      }
      this.drawer = false
    },
    async delExcludeSite (row) {
      if (this.type === 'edit') {
        const res = await delExcludeSite(row)
        if (res.code === 0) {
          this.$message.success(res.msg)
          this.selectExuldedSites()
        } else {
          this.$message.error(res.msg)
        }
      } else {
        for (var i = 0; i < this.exuldedArray.length; i++) {
          if (this.exuldedArray[i] === row) {
            this.exuldedArray.splice(i, 1)
          }
        }
      }
    }
  },
  mounted () {
    this.type = this.$route.meta.type
    this.cid = this.$route.params.cid
    this.userRole = JSON.parse(sessionStorage.getItem('curr_custom')).userRole
    if (this.type === 'edit') {
      this.viewselMenu(this.$route.params.id)
      eventHub.$emit('msg', '修改监测主题')
    } else if (this.type === 'add') {
      this.loading = false
      eventHub.$emit('msg', '添加监测主题')
    }
  }
}
</script>

<style lang="scss" scoped>
  .project-wrapper {
    width: 100%;
    border-radius: 8px;
    background: #fff;
    padding-top: 50px;
    .el-form {
      display: flex;
      flex-wrap: wrap;
      padding: 0 30px;
      .el-form-item {
        width: 50%;
        .el-input {
          width: 80%;
        }
        &.words{
          width: 100%;
        }
        textarea {
          width: 100%;
          height: 136px;
          border: 1px solid #DCDFE6;
          color: #606266;
          border-radius: 4px;
        }
        .rules{
          color: #8f8f94;
          font-size: 12px;
          line-height: 22px;
          margin: 0;
        }
      }
    }
  }
</style>
